import React from "react";
import AboutUsJourney from "../components/aboutUs/aboutUsJourney";
import AboutUsLeadership from "../components/aboutUs/aboutUsLeadership";
import AboutUsMain from "../components/aboutUs/aboutUsMain";
import Layout from "../components/Global/layout";
import SEO from "../components/seo/SEO";


const AboutUs = () => {
  return (
    <>
      <SEO />
      <Layout>
        <AboutUsMain />
        <AboutUsJourney />
        <AboutUsLeadership />
      </Layout>
    </>
  );
};

export default AboutUs;
