import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import React from "react";

const AboutUsLeadership = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicAboutUs {
        edges {
          node {
            data {
              about_us_title
              description
              subtitle
              promoters {
                designation
                name
                photo {
                  gatsbyImageData
                }
              }
              journey {
                journey_image {
                  gatsbyImageData
                }
                title
                year
              }
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <main className="grid grid-cols-[16px,1fr,16px] md:grid-cols-[1fr,6fr,1fr] lg:grid-cols-[1fr,3fr,1fr] xl:grid-cols-[1fr,2fr,1fr] gap-[1px] bg-gray-700 border-t-[1px] border-b-[1px] border-gray-700">
        <div className="p-4 bg-gray-900"></div>
        <div className="p-4 md:p-8 lg:p-12 bg-gray-900 flex flex-col space-y-4">
          <h3 className="text-2xl text-blue-500 font-semibold">Leadership</h3>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 md:gap-4">
            {data.allPrismicAboutUs.edges[0].node.data.promoters.map((item) => (
              <div className="bg-gray-800 flex-col space-y-2 rounded pb-0">
                <div className="bg-gray-700 aspect-w-1 aspect-h-1 rounded-t !pb-0">
                    <GatsbyImage 
                    image = {getImage(item.photo)}
                    />
                </div>
                <ul className="flex flex-col space-y-2 py-4">
                  <li>
                    <h3 className=" px-4 text-2xl text-blue-500">
                      {item.name}
                    </h3>
                  </li>
                  <li>
                    <p className="px-4 text-base text-gray-400">{item.designation}</p>
                  </li>
                </ul>
              </div>
            ))}
          </div>
        </div>
        <div className="p-4 bg-gray-900"></div>
      </main>
    </>
  );
};

export default AboutUsLeadership;
