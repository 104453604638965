import { ChevronRightIcon } from "@heroicons/react/solid";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

const AboutUsJourney = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicAboutUs {
        edges {
          node {
            data {
              about_us_title
              description
              subtitle
              promoters {
                designation
                name
                photo {
                  gatsbyImageData
                }
              }
              journey {
                journey_image {
                  gatsbyImageData
                }
                title
                year
              }
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <main className="grid grid-cols-[16px,1fr,16px] md:grid-cols-[1fr,6fr,1fr] lg:grid-cols-[1fr,3fr,1fr] xl:grid-cols-[1fr,2fr,1fr] gap-[1px] bg-gray-700 border-t-[1px] border-b-[1px] border-gray-700">
        <div className="p-4 bg-gray-900"></div>
        <div className="bg-gray-900 lg:hidden">
          <div className=" relative grid grid-cols-1 lg:grid-cols-2 gap-4 p-4 rounded-2xl pb-0">
            {data.allPrismicAboutUs.edges[0].node.data.journey.map((item) => (
              <div className=" relative col-span-1 lg:col-span-2 aspect-w-4 aspect-h-7 bg-gray-800 rounded-2xl !pb-0">
                <GatsbyImage
                  image={getImage(item.journey_image)}
                  className="rounded-2xl"
                />
                <div className="absolute top-[16px] inset-3 w-auto h-[140px] sm:h-[140px] lg:w-[300px] bg-gray-700 lg:inset-8 lg:h-auto rounded-2xl shadow-md">
                  <ul className="flex flex-col space-y-4 justify-center h-full items-start p-6 sm:p-8">
                    <h5 className="font-medium uppercase text-base text-blue-500">
                      {item.year}
                    </h5>
                    <h2 className="text-gray-300 text-xl sm:text-2xl leading-relaxed font-medium">
                    {item.title}
                    </h2>
                    
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-gray-900 hidden lg:block">
          <div className=" relative grid grid-cols-1 lg:grid-cols-2 gap-4 p-4 pb-0">
            {data.allPrismicAboutUs.edges[0].node.data.journey.map((item) => (
              <div className=" relative col-span-1 lg:col-span-2 lg:aspect-w-16 lg:aspect-h-10 bg-gray-800 rounded-2xl !pb-0">
                <GatsbyImage
                  image={getImage(item.journey_image)}
                  className="rounded-2xl"
                />
                <div className="absolute w-auto h-[220px] sm:h-[280px] lg:w-[320px] bg-gray-700 lg:inset-8 lg:h-[160px] lg:rounded-2xl shadow-md">
                  <ul className="flex flex-col space-y-4 justify-center h-full items-start p-6 sm:p-8">
                    <h5 className="font-medium uppercase text-base text-blue-500">
                      {item.year}
                    </h5>
                    <h2 className="text-gray-300 text-xl sm:text-2xl leading-relaxed font-medium">
                      {item.title}
                    </h2>
                    
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>

       

        <div className="p-4 bg-gray-900"></div>
      </main>
    </>
  );
};

export default AboutUsJourney;
