import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";


const AboutUsMain = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicAboutUs {
        edges {
          node {
            data {
              about_us_title
              description
              subtitle
              promoters {
                designation
                name
                photo {
                  gatsbyImageData
                }
              }
              journey {
                journey_image {
                  gatsbyImageData
                }
                title
                year
              }
            }
          }
        }
      }
    }
  `)
  return (
    
    <>
      <main className="mt-16 grid grid-cols-[16px,1fr,16px] md:grid-cols-[1fr,6fr,1fr] lg:grid-cols-[1fr,3fr,1fr] xl:grid-cols-[1fr,2fr,1fr] gap-[1px] bg-gray-700 border-t-[1px] border-gray-700">
        <div className="bg-gray-900"></div>
        <ul className="flex flex-col space-y-3 bg-gray-900 p-4 md:p-8 lg:p-12">
          <li>
            <h1 className="text-2xl text-blue-500 font-semibold">{data.allPrismicAboutUs.edges[0].node.data.about_us_title}</h1>
          </li>
          <li>
            <h2 className="text-3xl leading-normal text-gray-300 font-medium">
            {data.allPrismicAboutUs.edges[0].node.data.subtitle}
            </h2>
          </li>
          <li>
            <p className="text-base leading-relaxed text-gray-400 font-light md:pr-32 lg:pr-56">
            {data.allPrismicAboutUs.edges[0].node.data.description}
            </p>
          </li>
          <li>
          </li>
        </ul>
        <div className="bg-gray-900"></div>
        <div className="bg-gray-900 p-1"></div>
        <div className="bg-gray-900"></div>
        <div className="bg-gray-900"></div>
      </main>
    </>
  );
};

export default AboutUsMain;
